import { Spinner, SpinnerSize, Dialog, DialogType } from '@fluentui/react';
import styled from 'styled-components';
import React from "react";
import UploadStatus from './UploadStatus';

interface ILoaderProps {
    isVisible: boolean;
    message: string;
}

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: `We're working on it!`,

};

const StyledSpinner = styled(Spinner)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;

const Loader = (props: ILoaderProps) => {
    return props.isVisible ?
        <Dialog
            hidden={!props.isVisible}
            dialogContentProps={dialogContentProps}>
            <div style={{ marginBottom: '4rem' }}>
                <StyledSpinner aria-label='Loader' size={SpinnerSize.large} label={props.message} />
            </div>
            <UploadStatus />
        </Dialog>

        : null;
};

export default Loader;
