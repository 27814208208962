import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";


export const msalConfig = (): Configuration => ({
	auth: {
		clientId: process.env.REACT_APP_CLIENT_ID as string,
		authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
		redirectUri: window.location.origin,
		navigateToLoginRequestUrl: true,
		postLogoutRedirectUri: window.location.href
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
				if (containsPii) {
					return;
				}
				switch (level) {
				case LogLevel.Error:
					console.error(message);
					return;
				case LogLevel.Info:
					return;
				case LogLevel.Verbose:
					// eslint-disable-next-line no-console
					console.debug(message);
					return;
				case LogLevel.Warning:
					console.warn(message);
					return;
				}
			},
		},
		tokenRenewalOffsetSeconds: 300, // 5 minute pre-expiration token renewal
		allowRedirectInIframe: true,
	},
	cache: {
		cacheLocation: "localStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	}
});

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = {
//   scopes: ["User.Read"]
// };

export const loginRequest = (): PopupRequest => ({
	scopes: [],
	authority: msalConfig().auth.authority,
});

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};