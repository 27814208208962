import { MessageBar, MessageBarType } from "@fluentui/react";
import { useRecoilValue } from "recoil"
import { uploadStatusState } from "../Atom/PDFEditorState"
import React from "react";


const UploadStatus = () => {

    const statusMessage = useRecoilValue(uploadStatusState);

    if (statusMessage.status === 'error') {
        return <MessageBar aria-label='error' messageBarType={MessageBarType.error}>{statusMessage.message}</MessageBar>;
    }

    if (statusMessage.status === 'success') {
        return <MessageBar aria-label='success' messageBarType={MessageBarType.success}>{statusMessage.message}</MessageBar>;
    }

    else {
        return <></>;
    }
}

export default UploadStatus