import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { msalConfig } from "./authConfig";
import ValidationWrapper from "./Components/ValidationWrapper";
import PDFEditorWrapper from "./Components/PDFEditorWrapper";

const App = () => {

	const config = msalConfig();
	const msalInstance = new PublicClientApplication(config);

	return (
		<MsalProvider instance={msalInstance}>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				<UnauthenticatedTemplate><div><p>Please login</p></div></UnauthenticatedTemplate>
				<AuthenticatedTemplate>
				<PDFEditorWrapper />
				</AuthenticatedTemplate>
			</MsalAuthenticationTemplate>
		</MsalProvider>
	);
};

export default App;