import axios, { AxiosResponse } from "axios";
import IFileResponse from "../Interfaces/IFileResponse";

export async function getDocumentByBlobId(
    blobId: string,
    docType: string): Promise<IFileResponse> {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_PROTOCOL_SERVICE_BASE}/api/v1/document/internal/download/${blobId}/document_type/${docType}`, {
                headers: {
                    'authKey': process.env.REACT_APP_SERVICE_FABRIC_AUTH as string
                },
                responseType: 'blob'
            })
            .then((response: AxiosResponse<File>) => {
                if (response.status === 200) {
                    const file: File = response.data
                    const fileResponse: IFileResponse | any = { file, fileExtension: ".pdf" }
                    resolve(fileResponse)
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export async function uploadAssetEdits(
    documentData: any,
    medSolUser: string) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_PROTOCOL_SERVICE_BASE}/api/v1/document/internal/upload`,
            headers: {
                authKey: process.env.REACT_APP_SERVICE_FABRIC_AUTH as string,
                MedSolUsername: medSolUser
            },
            data: documentData

        }).then((response) => {
            if (response.status === 200) {
                resolve(true);
            }
        })
            .catch(() => {
                reject(false);
            });
    });
}