import { atom, RecoilState } from "recoil";
import IFileResponse from "../Interfaces/IFileResponse";
import IStatusMessage from "../Interfaces/IStatusMessage";
import IURLData from "../Interfaces/IURLData";
import { getURLBlobId } from "../Utilities/Helper";

export const PDFSourceState: RecoilState<IFileResponse> = atom({
    key: "PDFSourceState",
    default: {} as IFileResponse
});

export const URLDataState: RecoilState<IURLData> = atom({
    key: "URLDataState",
    default: getURLBlobId() as IURLData
});

export const PDFBlobUrlState: RecoilState<string> = atom({
    key: "PDFBlobUrlState",
    default: ""
});

export const uploadStatusState: RecoilState<IStatusMessage> = atom({
    key: "uploadStatusState",
    default: {} as IStatusMessage
});