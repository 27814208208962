import { DetailsHeader } from "@fluentui/react";
import IURLData from "../Interfaces/IURLData";

export function getURLBlobId(): IURLData {
  const queryString = window.parent.location.search;
  const urlParams = new URLSearchParams(queryString);
  const blobId = urlParams.get("blobId") || "";
  const userId = urlParams.get("userId") || "";
  const userName = urlParams.get("userName") || "";
  const apId = urlParams.get("apId") || "";
  const assetId = urlParams.get("assetId") || "";
  const assetName = urlParams.get("assetName") || "";
  const travId = urlParams.get("travId") || "";
  const affiliateId = urlParams.get("affiliateId") || "";
  const docType = urlParams.get("docType") || "";
  const parentId = urlParams.get("parentId") || "";
  const groupId = urlParams.get("groupId") || "";
  const dealId = urlParams.get("dealId") || "";
  const status = urlParams.get("status") || "";

  const urlData: IURLData = {
    apId: apId,
    assetId: assetId,
    assetName: assetName,
    blobId: blobId,
    travId: travId,
    affiliateId: affiliateId,
    userId: userId,
    userName: userName,
    docType: docType,
    parentId: parentId,
    groupId: groupId,
    dealId: dealId,
    status: status,
  };

  return urlData;
}
