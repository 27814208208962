import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import React from "react";

ReactDOM.render(
	<RecoilRoot>
		<App />
	</RecoilRoot>
	, document.getElementById("root"));
