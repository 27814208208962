import React, { useEffect } from "react";
import { getDocumentByBlobId } from "../Utilities/Queries";
import { PDFBlobUrlState, PDFSourceState, URLDataState } from "../Atom/PDFEditorState";
import { useRecoilState, useRecoilValue } from "recoil";
import PDFWebviewer from "./PDFWebviewer";

const PDFEditorWrapper = () => {

    const [, setPDFSource] = useRecoilState(PDFSourceState);
    const [, setPDFBlobUrl] = useRecoilState(PDFBlobUrlState);

    const urlData = useRecoilValue(URLDataState)

    useEffect(() => {
        const queryInfo = async () => {
            const File = await getDocumentByBlobId(urlData.blobId, urlData.docType);
            setPDFSource(File);
            setPDFBlobUrl(window.URL.createObjectURL(File.file));
        };

        queryInfo();


    }, []);


    return (

        <PDFWebviewer />


    );
}

export default PDFEditorWrapper;
