import WebViewer from "@pdftron/webviewer";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import "../App.css";
import {
  PDFBlobUrlState,
  uploadStatusState,
  URLDataState,
} from "../Atom/PDFEditorState";
import ILoader from "../Interfaces/ILoader";
import IStatusMessage from "../Interfaces/IStatusMessage";
import { uploadAssetEdits } from "../Utilities/Queries";
import Loader from "./Loader";

const PDFWebviewer = () => {
  const viewer = useRef<HTMLDivElement>(null);
  const [loader, setLoader] = useState<ILoader>({
    message: "Loading...",
    isVisible: true,
  });
  const [, setStatus] = useRecoilState<IStatusMessage>(uploadStatusState);

  const urlData = useRecoilValue(URLDataState);
  const pdfBlobUrl = useRecoilValue(PDFBlobUrlState);

  const generateFormData = async (blob: any, fileName: any) => {
    let metaDataValue =
      `{sourceSystem: '2'` +
      `,dealId: '${urlData.dealId}'` +
      `,uploadUser: '${urlData.userId}' ` +
      `,uploadUserName : '${urlData.userName}' ` +
      `,originalBlobId : '${urlData.blobId}'` +
      `,protocolAttributes: []`;

    let secondaryAssetData = "";
    if (urlData.groupId) {
      secondaryAssetData =
        `,parentAssetId: '${urlData.parentId}'` +
        `,assetGroupId: '${urlData.groupId}'`;
    }

    metaDataValue = metaDataValue + secondaryAssetData + "}";

    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("travelerId", `${urlData.travId}`);
    formData.append("dealAffiliateId", `${urlData.affiliateId}`);
    formData.append("protocolAssetId", `${urlData.assetId}`);
    formData.append("currentDocumentStatus", `${urlData.status}`);
    formData.append("assignmentProtocolId", `${urlData.apId}`);
    formData.append("metaData", metaDataValue);

    return formData;
  };
  // if using a class, equivalent of componentDidMount
  useEffect(() => {
    if (pdfBlobUrl !== "") {
      setLoader({ isVisible: false, message: "" });
      WebViewer(
        {
          path: "/webviewer/lib",
          initialDoc: pdfBlobUrl,
          fullAPI: true,
          licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY as string,
        },
        viewer.current
      ).then((instance) => {
        const { documentViewer, Annotations } = instance.Core;
        const annotManager = documentViewer.getAnnotationManager();

        // Add header button that will get file data on click
        instance.UI.setHeaderItems((header) => {
          header.push({
            type: "actionButton",
            img: "/files/upload.png",
            title: "Upload",
            onClick: async () => {
              setLoader({ isVisible: true, message: "Uploading..." });
              const doc = documentViewer.getDocument();
              const xfdfString = await annotManager.exportAnnotations();
              const data = await doc.getFileData({
                // saves the document with annotations in it
                xfdfString,
              });
              const arr = new Uint8Array(data);
              const pdfBlob = new Blob([arr], { type: "application/pdf" });

              // add code for handling pdfBlob here
              const generatedMetaData = await generateFormData(
                pdfBlob,
                `${urlData.assetName}.pdf`
              );
              await uploadAssetEdits(generatedMetaData, urlData.userName)
                .then(() => {
                  setTimeout(() => {
                    setStatus({
                      status: "success",
                      message: "Document upload was successful!",
                    });
                    setLoader({ isVisible: false, message: "" });
                    window.close();
                  }, 2500);
                })
                .catch(() => {
                  setStatus({
                    status: "error",
                    message: "Document upload failed!",
                  });

                  setTimeout(() => {
                    setStatus({} as IStatusMessage);
                    setLoader({ isVisible: false, message: "" });
                  }, 10000);
                });
            },
          });
          instance.UI.disableFeatures([
            instance.UI.Feature.Download,
            instance.UI.Feature.Print,
          ]);
        });
      });
    }
  }, [pdfBlobUrl]);

  return (
    <div className="App" style={{ height: "100vh", width: "100%" }}>
      <Loader isVisible={loader.isVisible} message={loader.message} />
      <div className="header">
        PDF Editor - <i>Powered By PDFTron</i>
      </div>
      <div
        ref={viewer}
        style={{
          flex: 1,
          margin: "8px",
          webkitBoxShadow: "1px 1px 10px #999",
          boxShadow: "1px 1px 10px #999",
        }}
      ></div>
    </div>
  );
};

export default PDFWebviewer;
